.chatMessageToolCallItem {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: var(--inner-spacing-2);
  align-items: flex-start;
}

.iconInProgress {
  color: var(--color-fg-accent);
}

.iconPendingApproval {
  color: var(--color-bg-warning);
}

.iconSuccess {
  color: var(--color-bg-positive);
}

.iconError {
  color: var(--color-bg-negative);
}

.arguments {
  width: 100%;
  white-space: break-spaces;
  padding: var(--inner-spacing-3);
  gap: var(--inner-spacing-3);
  border-radius: var(--border-radius-elevation-3);
  background: var(--color-bg-neutral-subtle);
}

.argumentsHeader {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.argumentsHeader:hover {
  background-color: var(--color-bg-neutral-subtle);
}

.inProgressText {
  /* Add padding to prevent text clipping */
  padding: 3px 0;
  /* Ensure the background extends fully */
  /* Add a small margin to give breathing room */
  margin: -3px 0;
  background: linear-gradient(
    90deg,
    var(--color-bg-elevation-2) 0%,
    var(--color-fg-accent) 50%,
    var(--color-bg-elevation-2) 100%
  );
  background-position: center;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  background-size: 200% 100%;
  animation: shimmer 1.6s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.functionNameClickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-fg-accent);
}

.functionNameClickable:hover {
  color: var(--color-fg-accent-hover);
}
